// Bootstrap
$font-size-base: 1rem;

$font-family-sans-serif: 'ABCRepro', sans-serif;
$font-family-serif: 'ABCRepro', sans-serif;
$fonts-path: '/assets/fonts/';

$font-sizes: (
  1: 60px,
  2: 48px,
  3: 36px,
  4: 32px,
  5: 24px,
  6: 20px,
  body-xxs: 12px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 18px,
  body-lg: 20px,
  body-xl: 28px,
);

// golden ratio uitgaande vanuit grootste font formaat)
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 900;
$headings-line-height: 1;
$headings-margin-bottom: 1ex;

$display-font-family: $font-family-serif;
$display-font-style: null;
$display-font-weight: 900;
$display-line-height: $headings-line-height;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;

$lead-font-size: map-get($font-sizes, body-md);
$lead-font-weight: 700;

// Other
//? Put own extra variables here

$paragraph-line-height: 1.5;
