.button {
  --button-bg: var(--bs-gray-300);
  --button-color: white;
  --button-size: #{rem($button-base-size)};
  --button-padding-x: #{rem($button-padding-x)};
  --button-border-width: 1px;
  --button-border-radius: #{$button-border-radius};
  --button-font-size: 1rem;
  --button-icon-size: 1em;

  @extend %button-reset;

  position: relative;
  height: var(--button-size);

  padding-right: var(--button-padding-x);
  padding-left: var(--button-padding-x);
  padding-bottom: 0.1em;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: spacer(2);
  flex: 0 0 auto;

  font-size: var(--button-font-size);
  font-family: $button-font-family;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;

  transition: all 200ms ease-in-out;
  cursor: pointer;

  background-color: var(--button-bg);
  color: var(--button-color);
  fill: currentColor;

  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid transparent;

  @media (hover: hover) {
    &:hover {
      background-color: color-mix(in srgb, var(--button-bg) #{100% - $button-hover-shade-amount}, black);
      color: var(--button-color);
    }
  }

  &:active {
    background-color: color-mix(in srgb, var(--button-bg) #{100% - $button-active-shade-amount}, black);
    color: var(--button-color);
  }

  &:disabled,
  &[disabled='true'] {
    pointer-events: none;
    opacity: 0.4;
  }

  // Icons in buttons
  --button-icon-inset: calc(var(--button-padding-x) / 2 * -1); // Reset some spacing for icons

  > span + fstr-icon,
  > span + .chip,
  > span + .button__icon-inset {
    margin-right: var(--button-icon-inset);
    margin-left: 0;
  }

  > fstr-icon,
  > .chip,
  &__icon-inset {
    margin-left: var(--button-icon-inset);

    min-width: var(--button-icon-size);
    min-height: var(--button-icon-size);
  }
}

// hover animatie testje

.button:not(.button-link),
.mat-mdc-form-field.form-field--button-select {
  transition: all 0.2s cubic-bezier(0.24, 0.38, 0, 1);
  transform-origin: center;

  @media (hover: hover) {
    &:hover {
      scale: 0.94;
    }
  }
}
