.header {
  --header-background-color: var(--bs-accent);
  --header-highlight-color: var(--bs-secondary);

  background-color: var(--header-background-color);
  color: var(--header-color);

  .text-highlight {
    color: var(--header-highlight-color);
  }

  &--accent {
  }

  &--secondary {
    --header-background-color: var(--bs-secondary);
    --header-color: var(--bs-light);
    --header-highlight-color: var(--bs-accent);
  }
}
