.quick-nav {
  display: flex;
  gap: spacer(2);
  flex-wrap: wrap;

  &__prefix {
    pointer-events: none;
    background-color: transparent;
    color: black;
    padding-left: 0;
    padding-right: spacer(1);
  }
}
