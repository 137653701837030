.fr-inner {
  color: $text-muted;
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}

img {
  margin-top: rem(20px);
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  border-radius: var(--media-border-radius, var(--bs-border-radius));

  @include media-breakpoint-up(lg) {
    margin-left: calc(var(--content-media-outset) * -1);
    margin-right: calc(var(--content-media-outset) * -1);
    width: calc(100% + var(--content-media-outset) * 2);
    max-width: none;
  }
}
