.markdown-content--stripped {
  img {
    display: none;
  }

  .embed-removed {
    display: none;
  }
}

.markdown-content {
  blockquote {
    padding: 0;
    margin: 0 0 3ex 0;
    text-align: start;

    &::before {
      display: none;
    }
  }
}
