.placeholder-image {
  aspect-ratio: var(--placeholder-aspect-ratio);
  background-image: var(--placeholder-image);
  background-size: cover;

  @container (max-width: 400px) {
    background-size: 210px;
  }
}

fstr-image {
  container-type: inline-size;
}
