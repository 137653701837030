.button.button-link {
  --button-icon-inset: 0;
  --button-icon-size: 1.5em;

  background: transparent;
  color: var(--button-bg);
  padding: 0;
  border-radius: 0;

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: color-mix(in srgb, var(--button-bg) #{100% - $button-hover-shade-amount}, black);
    }
  }
}
