.swiper-container-wrapper {
  overscroll-behavior-x: none;
  position: relative;
  display: block;

  &:not(:hover) {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
      pointer-events: none;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    margin: auto;
    //opacity: 0.5;

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .swiper-button-next {
    right: 0;

    @include media-breakpoint-up(lg) {
      transform: translateX(calc(100% + 10px));
    }
  }
  .swiper-button-prev {
    left: 0;

    @include media-breakpoint-up(lg) {
      transform: translateX(calc((100% + 10px) * -1));
    }
  }

  //.swiper-button-prev.pull-s {
  //  @include media-breakpoint-up(lg) {
  //    --container-max-width: #{map-get($text-max-widths, sm)};
  //    --space-left: calc(((100vw - var(--container-max-width) + (var(--bs-gutter-x) * 2)) / 2) * -1);
  //    left: 30px;
  //  }
  //  margin-left: var(--space-left);
  //}
  //.swiper-button-next.pull-e {
  //  @include media-breakpoint-up(lg) {
  //    --container-max-width: #{map-get($text-max-widths, sm)};
  //    --space-left: calc(((100vw - var(--container-max-width) + (var(--bs-gutter-x) * 2)) / 2) * -1);
  //    right: 30px;
  //  }
  //  margin-right: var(--space-left);
  //}
}
