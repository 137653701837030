.mdc-radio__outer-circle {
  --mdc-radio-unselected-icon-color: var(--bs-primary);
}

.mat-mdc-radio-button .mdc-radio__outer-circle {
  border-width: 1px !important;
}

// reset touch spacing
.mat-mdc-radio-button .mdc-radio {
  margin-left: calc(((var(--mdc-radio-state-layer-size) - 20px) / 2) * -1);
}
