body {
  font-optical-sizing: auto;
}

%heading {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto; // For this to work html lang attribute must be set to the correct language
  font-optical-sizing: auto;
  text-wrap: balance;
}

h1,
.h1 {
  @extend %heading;
}

h2,
.h2 {
  @extend %heading;
}

h3,
.h3 {
  @extend %heading;
}

h4,
.h4 {
  @extend %heading;
}

h5,
.h5 {
  @extend %heading;
}

h6,
.h6 {
  @extend %heading;
}

.caption {
  font-size: rem(12px) !important;
  font-style: italic !important;
  color: var(--bs-tertiary-color) !important;
  font-weight: 500 !important;

  text-align: start;
  line-height: 1.3;
}

.lead {
  font-size: 1.2em;
  font-weight: 500;
}

.subtitle {
  font-weight: 500;
  display: inline-block;
  width: 100%;

  line-height: 1.3;
  text-wrap: balance;

  @include get-font-size(body-sm);

  @include media-breakpoint-up(md) {
    @include get-font-size(6);
  }
}
