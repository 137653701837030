.link-reset {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.text-primary-subtle {
  color: var(--bs-primary-bg-subtle) !important;
}

//@for $i from 1 through 9 {
//  .text-primary-#{$i}00 {
//    color: var(--bs-primary-#{$i}00) !important;
//  }
//}

// Override the default contrast text color based on preferrance
.text-bg-primary,
.text-bg-info,
.text-bg-success {
  color: white !important;
}

.text-bg-primary {
  a:not(.button),
  .markdown-content a:not(.button) {
    color: var(--bs-accent);

    @media (hover: hover) {
      &:hover {
        color: color-mix(in srgb, var(--bs-accent) 80%, black);
      }
    }
  }
}

// for 3 items
@for $i from 1 through 5 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// Fixes cut off text at the bottom (what you will get with normal margin-bottom:0)
.mb-max-lines {
  padding-bottom: 0.1em;
  margin-bottom: -0.1em;
}

.text-truncate {
  overflow: hidden !important;
}

.hover--underline-effect {
  height: auto;

  &:after {
    content: '';
    position: absolute;
    bottom: -0.35em;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    background-color: currentColor;

    transition: transform 0.3s;
  }

  &.is--active {
    color: var(--bs-primary);
    &:after {
      transform: scaleX(1);
    }
  }

  @media (hover: hover) {
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

.hover--underline {
  @media (hover: hover) {
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.fw-semi {
  font-weight: 600 !important;
}

.pre-line {
  white-space: pre-line !important;
}

.nowrap {
  white-space: nowrap !important;
}

.break-word {
  word-break: break-word !important;
  hyphens: auto;
}
