.card {
  --bs-card-aspect-ratio: #{$card-aspect-ratio};
  --bs-card-inner-border-radius: calc(var(--bs-card-border-radius, var(--bs-border-radius)) - 0px);

  @include media-breakpoint-down(md) {
    --bs-card-border-radius: var(--bs-border-radius-sm);
  }
}

.card-img-overlay {
  position: relative;
  aspect-ratio: var(--bs-card-aspect-ratio, 1/0.75);
}

.card-img-background {
  @include abs(0);
  background-color: black;

  img {
    opacity: 0.6;
  }
}

test-fstr-image,
fstr-image {
  &.card-img {
    display: block;
    overflow: hidden;
  }

  &.card-img,
  &.card-img-top {
    img {
      @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
    }
  }

  &.card-img,
  &.card-img-bottom {
    img {
      @include border-bottom-radius(var(--#{$prefix}card-inner-border-radius));
    }
  }
}
