.mat-mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: var(--bs-light-border-subtle);
  --mdc-checkbox-unselected-hover-icon-color: var(--bs-tertiary-color);
  --mdc-checkbox-state-layer-size: 32px;

  .mdc-form-field {
    align-items: center;
    color: var(--bs-body-color);
    gap: 0.3rem;
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
    background: transparent !important;
    border: solid 1px var(--bs-primary);
  }

  .mdc-checkbox__background {
    border-radius: 5px;
    background-color: var(--bs-tertiary-bg);
    border: 1px solid var(--bs-primary);
    @include size(20px);
  }

  .mdc-checkbox__checkmark {
    padding: 2px;
  }
  &-checked {
    .mdc-label {
      color: var(--bs-primary);
    }
  }
}
