body {
  --mat-expansion-container-shape: 15px;
  --panel-spacing-x: 24px;
}

.mat-expansion-panel {
  border: 1px solid $gray-200;

  @include media-breakpoint-down(md) {
    --panel-spacing-x: 17px;
  }

  .mat-expansion-panel-body,
  .mat-expansion-panel-header {
    padding-left: var(--panel-spacing-x);
    padding-right: var(--panel-spacing-x);
  }

  .mat-expansion-panel-header {
    height: auto;
    min-height: var(--mat-expansion-header-collapsed-state-height);
    padding-top: 15px;
    padding-bottom: 15px;

    &.mat-expanded {
      height: auto;
    }
  }

  &:not(.mat-expanded) {
    margin: -1px 0;
  }

  &:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
  }
  .mat-expansion-indicator::after {
    color: var(--bs-primary);
  }

  &.mat-before-expanded {
    border-bottom-left-radius: var(--mat-expansion-container-shape) !important;
    border-bottom-right-radius: var(--mat-expansion-container-shape) !important;
  }

  &.mat-after-expanded {
    border-top-left-radius: var(--mat-expansion-container-shape) !important;
    border-top-right-radius: var(--mat-expansion-container-shape) !important;
  }

  &.no--border-around {
    border-right: none;
    border-left: none;

    &:first-of-type {
      border-top: none;
    }
    &:last-of-type {
      border-bottom: none;
    }

    &.mat-expanded {
      border-top: none;
      border-bottom: 0;
    }

    &.mat-before-expanded {
      border-bottom: 0;
    }
    &.mat-after-expanded {
      border-top: none;
    }
  }
}

// animating bugfix where the panels are open until animation is finished
.ng-animating.mat-expansion-panel:not(.mat-expanded),
.ng-animating .mat-expansion-panel:not(.mat-expanded) {
  pointer-events: none !important;

  .mat-expansion-panel-content {
    max-height: 0;
  }
}
