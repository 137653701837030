.header-content-bar {
  &__panel {
    padding-top: spacer(3);
    position: relative;

    &::before {
      content: '';
      @include abs(0, calc(var(--panel-offset-x) * -1), 0, calc(var(--panel-offset-x) * -1));
      background-color: white;
      border-top-right-radius: var(--bs-border-radius);
      border-top-left-radius: var(--bs-border-radius);
    }
  }
}
