.badge {
  --#{$prefix}badge-padding-x: #{$badge-padding-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-y};
  @include rfs($badge-font-size, --#{$prefix}badge-font-size);
  --#{$prefix}badge-font-weight: #{$badge-font-weight};
  --#{$prefix}badge-color: #{$badge-color};
  --#{$prefix}badge-border-radius: #{$badge-border-radius};

  display: inline-block;
  padding: var(--#{$prefix}badge-padding-y) var(--#{$prefix}badge-padding-x);
  @include font-size(var(--#{$prefix}badge-font-size));
  font-weight: var(--#{$prefix}badge-font-weight);
  line-height: 1;
  color: var(--#{$prefix}badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius(var(--#{$prefix}badge-border-radius));
  @include gradient-bg();
}
