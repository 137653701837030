blockquote {
  position: relative;
  padding: 0 0 1.25em;
  margin: 4ex 0 4ex 0;
  font-family: $font-family-serif;
  text-align: center;
  display: flex;
  flex-flow: column;
  color: var(--bs-secondary);

  &::before {
    content: '“';
    font-size: 3em;
    font-family: $font-family-serif;
    font-weight: bold;
    color: var(--bs-primary);
    height: 1.5ex;
    line-height: 1;
    display: inline-block;
  }

  * {
    font-size: 1.2em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
