@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.anim--spinning {
  animation: spin 2.2s linear infinite;
}
