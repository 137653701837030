.field-round {
  .mat-mdc-text-field-wrapper {
    border-radius: 100px;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-icon-suffix .button {
    margin-right: 7px;
  }
}
