body {
  @include tint-css-variables('primary');
  @include tint-css-variables('secondary');

  --bs-secondary-100: #f6f6ff;

  @each $name, $value in $border-radius-list {
    --bs-border-radius-#{$name}: #{$value};
  }

  --media-border-radius: var(--bs-border-radius-sm);
  @include media-breakpoint-up(md) {
    --media-border-radius: var(--bs-border-radius);
  }

  @each $breakpoint, $size in $section-spacers {
    @include media-breakpoint-up($breakpoint) {
      --section-spacing: #{$size};
    }
  }

  --panel-offset-x: 20px;
}
