.mat-mdc-option {
  &.mdc-list-item {
    align-items: flex-start !important;
  }

  .mat-pseudo-checkbox {
    margin-top: 1em;
  }
  .mdc-list-item__primary-text {
    margin-top: 1em;
    padding-bottom: 0.8em;
  }
}
