.mat-mdc-select-value,
.mat-mdc-select-arrow {
  color: var(--bs-primary-800);
}

//.cdk-overlay-pane {
//  min-width: 320px;
//}

.mat-mdc-select-panel {
  max-height: 355px;

  --scrollbar-thumb-bg: var(--bs-primary);
  --scrollbar-track-bg: var(--bs-primary-100);

  @include scrollbar;
}
