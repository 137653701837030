.mat-calendar-controls {
  justify-content: space-between;

  .mat-calendar-spacer {
    display: none;
  }

  .mat-calendar-previous-button {
    order: -1;
  }
}

.mat-calendar-body-label {
  color: transparent !important;
  padding-top: 0 !important;

  &[colspan='7'] {
    display: none;
  }
}

.mat-calendar {
  padding-top: 0rem !important;
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--bs-primary-bg-subtle);
    border: none;
  }
}
.mdc-button__label {
  color: var(--bs-dark);
}
.mat-mdc-menu-panel {
  max-width: fit-content !important;
}
.mat-calendar-table-header th {
  font-weight: bold !important;
  color: var(--bs-dark) !important;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  background-color: var(--bs-primary-bg-subtle);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  background-color: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
}
.mat-calendar-table-header-divider::after {
  background-color: transparent !important;
}
