@include media-breakpoint-up(sm) {
  .h-sm-100 {
    height: 100% !important;
  }
}

.w-0 {
  width: 0 !important;
}

.min-width-0 {
  min-width: 0 !important;
}
