%svg-common {
  background: url("/assets/dist/svg/scss/svg/sprite.css.svg") no-repeat;
  display: inline-block;
}

.css-icon-alert-circle {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-alert-circle"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-alert-octagon {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.297029702970297%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-alert-octagon"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-alert-triangle {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.594059405940594%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-alert-triangle"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-arrow-down {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.8910891089108911%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-arrow-down"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-arrow-left {
  --icon-width: 14;
  --icon-height: 13;
  --relative-pos: 0 1.1865035224323321%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-arrow-left"] {
  --icon-width: 14;
  --icon-height: 13;
}
.css-icon-arrow-right {
  --icon-width: 14;
  --icon-height: 13;
  --relative-pos: 0 1.3471758744283773%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-arrow-right"] {
  --icon-width: 14;
  --icon-height: 13;
}
.css-icon-arrow-up {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 1.50990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-arrow-up"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-check {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 1.806930693069307%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-check"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-icon-chevron-down {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 2.103960396039604%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-chevron-down"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-chevron-left {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 2.400990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-chevron-left"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-chevron-right {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 2.698019801980198%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-chevron-right"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-chevron-up {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 2.995049504950495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-chevron-up"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-external-link {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 3.292079207920792%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-external-link"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-facebook {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 3.589108910891089%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-facebook"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-heart {
  --icon-width: 16;
  --icon-height: 15;
  --relative-pos: 0 3.881814810236123%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-heart"] {
  --icon-width: 16;
  --icon-height: 15;
}
.css-icon-hearth {
  --icon-width: 16;
  --icon-height: 14;
  --relative-pos: 0 4.066749072929543%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-hearth"] {
  --icon-width: 16;
  --icon-height: 14;
}
.css-icon-instagram {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 4.2450495049504955%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-instagram"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-line-facebook {
  --icon-width: 13;
  --icon-height: 22;
  --relative-pos: 0 4.540955209106657%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-line-facebook"] {
  --icon-width: 13;
  --icon-height: 22;
}
.css-icon-line-instagram {
  --icon-width: 22;
  --icon-height: 23;
  --relative-pos: 0 4.813760673184012%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-line-instagram"] {
  --icon-width: 22;
  --icon-height: 23;
}
.css-icon-line-x {
  --icon-width: 20;
  --icon-height: 19;
  --relative-pos: 0 5.095856524427953%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-line-x"] {
  --icon-width: 20;
  --icon-height: 19;
}
.css-icon-line-youtube {
  --icon-width: 24;
  --icon-height: 18;
  --relative-pos: 0 5.3302003462775165%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-line-youtube"] {
  --icon-width: 24;
  --icon-height: 18;
}
.css-icon-link {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 5.556930693069307%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-link"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-linkedin {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 5.853960396039604%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-linkedin"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-mail {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 6.150990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-mail"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-marker {
  --icon-width: 44;
  --icon-height: 53;
  --relative-pos: 0 6.471245807974165%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-marker"] {
  --icon-width: 44;
  --icon-height: 53;
}
.css-icon-play-fill {
  --icon-width: 150;
  --icon-height: 200;
  --relative-pos: 0 7.262145748987854%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-play-fill"] {
  --icon-width: 150;
  --icon-height: 200;
}
.css-icon-spinner {
  --icon-width: 200;
  --icon-height: 200;
  --relative-pos: 0 9.79251012145749%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-spinner"] {
  --icon-width: 200;
  --icon-height: 200;
}
.css-icon-x {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 12.054455445544555%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-x"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-icon-x-octagon {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 12.351485148514852%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="icon-x-octagon"] {
  --icon-width: 25;
  --icon-height: 24;
}
.css-line-icon-activity {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 12.648514851485148%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-activity"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-airplay {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 12.945544554455445%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-airplay"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-alert-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 13.242574257425742%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-alert-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-alert-octagon {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 13.53960396039604%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-alert-octagon"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-alert-triangle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 13.836633663366337%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-alert-triangle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-align-center {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 14.133663366336634%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-align-center"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-align-justify {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 14.430693069306932%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-align-justify"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-align-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 14.727722772277227%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-align-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-align-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 15.024752475247524%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-align-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-anchor {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 15.321782178217822%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-anchor"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-aperture {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 15.618811881188119%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-aperture"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-archive {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 15.915841584158416%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-archive"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 16.212871287128714%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-down-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 16.50990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-down-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-down-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 16.806930693069308%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-down-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-down-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 17.103960396039604%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-down-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 17.400990099009903%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-left-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 17.698019801980198%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-left-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 17.995049504950494%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-right-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 18.292079207920793%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-right-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 18.58910891089109%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-up-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 18.886138613861387%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-up-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-up-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 19.183168316831683%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-up-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-arrow-up-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 19.480198019801982%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-arrow-up-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-at-sign {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 19.777227722772277%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-at-sign"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-award {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 20.074257425742573%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-award"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bar-chart {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 20.371287128712872%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bar-chart"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bar-chart-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 20.668316831683168%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bar-chart-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-battery {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 20.965346534653467%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-battery"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-battery-charging {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 21.262376237623762%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-battery-charging"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bell {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 21.559405940594058%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bell"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bell-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 21.856435643564357%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bell-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bluetooth {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 22.153465346534652%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bluetooth"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bold {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 22.45049504950495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bold"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-book {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 22.747524752475247%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-book"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-book-open {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 23.044554455445546%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-book-open"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-bookmark {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 23.34158415841584%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-bookmark"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-box {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 23.638613861386137%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-box"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-briefcase {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 23.935643564356436%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-briefcase"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-calendar {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 24.23267326732673%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-calendar"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-camera {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 24.52970297029703%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-camera"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-camera-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 24.826732673267326%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-camera-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cast {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 25.123762376237625%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cast"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-check {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 25.42079207920792%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-check"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-check-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 25.717821782178216%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-check-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-check-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 26.014851485148515%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-check-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevron-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 26.31188118811881%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevron-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevron-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 26.60891089108911%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevron-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevron-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 26.905940594059405%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevron-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevron-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 27.202970297029704%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevron-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevrons-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 27.5%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevrons-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevrons-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 27.797029702970296%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevrons-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevrons-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 28.094059405940595%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevrons-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chevrons-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 28.39108910891089%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chevrons-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-chrome {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 28.68811881188119%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-chrome"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 28.985148514851485%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-clipboard {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 29.282178217821784%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-clipboard"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-clock {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 29.57920792079208%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-clock"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cloud {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 29.876237623762375%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cloud"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cloud-drizzle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 30.173267326732674%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cloud-drizzle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cloud-lightning {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 30.47029702970297%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cloud-lightning"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cloud-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 30.76732673267327%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cloud-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cloud-rain {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 31.064356435643564%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cloud-rain"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cloud-snow {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 31.361386138613863%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cloud-snow"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-code {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 31.65841584158416%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-code"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-codepen {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 31.955445544554454%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-codepen"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-codesandbox {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 32.25247524752475%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-codesandbox"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-coffee {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 32.54950495049505%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-coffee"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-columns {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 32.84653465346535%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-columns"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-command {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 33.14356435643565%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-command"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-compass {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 33.44059405940594%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-compass"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-copy {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 33.73762376237624%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-copy"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-down-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 34.03465346534654%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-down-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-down-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 34.33168316831683%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-down-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-left-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 34.62871287128713%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-left-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-left-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 34.92574257425743%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-left-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-right-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 35.222772277227726%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-right-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-right-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 35.51980198019802%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-right-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-up-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 35.81683168316832%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-up-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-corner-up-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 36.113861386138616%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-corner-up-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-cpu {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 36.41089108910891%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-cpu"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-credit-card {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 36.70792079207921%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-credit-card"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-crop {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 37.004950495049506%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-crop"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-crosshair {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 37.301980198019805%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-crosshair"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-database {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 37.5990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-database"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-delete {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 37.896039603960396%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-delete"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-disc {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 38.193069306930695%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-disc"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-divide {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 38.49009900990099%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-divide"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-divide-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 38.78712871287129%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-divide-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-divide-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 39.084158415841586%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-divide-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-dollar-sign {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 39.381188118811885%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-dollar-sign"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-download {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 39.67821782178218%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-download"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-download-cloud {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 39.975247524752476%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-download-cloud"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-dribbble {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 40.272277227722775%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-dribbble"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-droplet {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 40.56930693069307%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-droplet"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-edit {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 40.866336633663366%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-edit"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-edit-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 41.163366336633665%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-edit-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-edit-3 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 41.460396039603964%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-edit-3"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-external-link {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 41.757425742574256%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-external-link"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-eye {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 42.054455445544555%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-eye"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-eye-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 42.351485148514854%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-eye-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-facebook {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 42.648514851485146%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-facebook"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-fast-forward {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 42.945544554455445%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-fast-forward"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-feather {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 43.242574257425744%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-feather"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-figma {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 43.539603960396036%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-figma"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-file {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 43.836633663366335%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-file"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-file-minus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 44.133663366336634%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-file-minus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-file-plus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 44.43069306930693%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-file-plus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-file-text {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 44.727722772277225%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-file-text"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-film {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 45.024752475247524%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-film"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-filter {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 45.32178217821782%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-filter"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-flag {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 45.618811881188115%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-flag"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-folder {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 45.915841584158414%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-folder"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-folder-minus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 46.21287128712871%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-folder-minus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-folder-plus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 46.50990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-folder-plus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-framer {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 46.806930693069305%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-framer"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-frown {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 47.103960396039604%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-frown"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-gift {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 47.4009900990099%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-gift"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-git-branch {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 47.698019801980195%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-git-branch"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-git-commit {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 47.995049504950494%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-git-commit"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-git-merge {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 48.29207920792079%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-git-merge"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-git-pull-request {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 48.58910891089109%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-git-pull-request"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-github {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 48.886138613861384%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-github"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-gitlab {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 49.18316831683168%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-gitlab"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-globe {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 49.48019801980198%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-globe"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-grid {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 49.777227722772274%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-grid"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-hard-drive {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 50.07425742574257%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-hard-drive"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-hash {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 50.37128712871287%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-hash"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-headphones {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 50.66831683168317%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-headphones"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-heart {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 50.96534653465346%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-heart"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-help-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 51.26237623762376%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-help-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-hexagon {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 51.55940594059406%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-hexagon"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-home {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 51.85643564356435%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-home"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-image {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 52.15346534653465%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-image"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-inbox {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 52.45049504950495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-inbox"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-info {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 52.74752475247525%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-info"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-instagram {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 53.04455445544554%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-instagram"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-italic {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 53.34158415841584%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-italic"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-key {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 53.63861386138614%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-key"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-layers {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 53.93564356435643%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-layers"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-layout {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 54.23267326732673%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-layout"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-life-buoy {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 54.52970297029703%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-life-buoy"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-link {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 54.82673267326733%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-link"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-link-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 55.12376237623762%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-link-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-linkedin {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 55.42079207920792%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-linkedin"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-list {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 55.71782178217822%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-list"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-loader {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 56.01485148514851%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-loader"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-lock {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 56.31188118811881%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-lock"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-log-in {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 56.60891089108911%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-log-in"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-log-out {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 56.90594059405941%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-log-out"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-mail {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 57.2029702970297%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-mail"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-map {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 57.5%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-map"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-map-pin {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 57.7970297029703%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-map-pin"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-maximize {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 58.09405940594059%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-maximize"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-maximize-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 58.39108910891089%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-maximize-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-meh {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 58.68811881188119%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-meh"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-menu {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 58.98514851485149%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-menu"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-message-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 59.28217821782178%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-message-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-message-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 59.57920792079208%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-message-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-mic {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 59.87623762376238%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-mic"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-mic-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 60.17326732673267%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-mic-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-minimize {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 60.47029702970297%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-minimize"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-minimize-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 60.76732673267327%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-minimize-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-minus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 61.06435643564357%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-minus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-minus-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 61.36138613861386%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-minus-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-minus-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 61.65841584158416%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-minus-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-monitor {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 61.95544554455446%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-monitor"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-moon {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 62.25247524752475%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-moon"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-more-horizontal {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 62.54950495049505%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-more-horizontal"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-more-vertical {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 62.84653465346535%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-more-vertical"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-mouse-pointer {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 63.14356435643565%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-mouse-pointer"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-move {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 63.44059405940594%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-move"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-music {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 63.73762376237624%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-music"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-navigation {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 64.03465346534654%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-navigation"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-navigation-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 64.33168316831683%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-navigation-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-octagon {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 64.62871287128714%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-octagon"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-package {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 64.92574257425743%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-package"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-paperclip {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 65.22277227722772%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-paperclip"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-pause {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 65.51980198019803%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-pause"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-pause-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 65.81683168316832%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-pause-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-pen-tool {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 66.11386138613861%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-pen-tool"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-percent {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 66.41089108910892%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-percent"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 66.70792079207921%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone-call {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 67.0049504950495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone-call"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone-forwarded {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 67.3019801980198%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone-forwarded"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone-incoming {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 67.5990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone-incoming"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone-missed {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 67.89603960396039%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone-missed"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 68.1930693069307%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-phone-outgoing {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 68.49009900990099%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-phone-outgoing"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-pie-chart {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 68.7871287128713%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-pie-chart"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-play {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 69.08415841584159%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-play"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-play-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 69.38118811881188%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-play-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-plus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 69.67821782178218%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-plus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-plus-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 69.97524752475248%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-plus-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-plus-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 70.27227722772277%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-plus-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-pocket {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 70.56930693069307%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-pocket"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-power {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 70.86633663366337%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-power"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-printer {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 71.16336633663366%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-printer"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-radio {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 71.46039603960396%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-radio"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-refresh-ccw {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 71.75742574257426%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-refresh-ccw"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-refresh-cw {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 72.05445544554455%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-refresh-cw"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-repeat {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 72.35148514851485%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-repeat"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-rewind {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 72.64851485148515%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-rewind"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-rotate-ccw {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 72.94554455445545%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-rotate-ccw"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-rotate-cw {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 73.24257425742574%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-rotate-cw"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-rss {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 73.53960396039604%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-rss"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-save {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 73.83663366336634%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-save"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-scissors {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 74.13366336633663%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-scissors"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-search {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 74.43069306930693%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-search"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-send {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 74.72772277227723%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-send"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-server {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 75.02475247524752%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-server"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-settings {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 75.32178217821782%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-settings"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-share {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 75.61881188118812%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-share"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-share-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 75.91584158415841%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-share-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-shield {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 76.2128712871287%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-shield"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-shield-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 76.50990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-shield-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-shopping-bag {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 76.8069306930693%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-shopping-bag"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-shopping-cart {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 77.10396039603961%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-shopping-cart"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-shuffle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 77.4009900990099%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-shuffle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-sidebar {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 77.6980198019802%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-sidebar"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-skip-back {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 77.9950495049505%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-skip-back"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-skip-forward {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 78.29207920792079%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-skip-forward"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-slack {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 78.58910891089108%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-slack"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-slash {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 78.88613861386139%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-slash"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-sliders {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 79.18316831683168%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-sliders"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-smartphone {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 79.48019801980197%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-smartphone"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-smile {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 79.77722772277228%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-smile"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-speaker {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 80.07425742574257%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-speaker"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 80.37128712871286%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-star {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 80.66831683168317%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-star"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-stop-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 80.96534653465346%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-stop-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-sun {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 81.26237623762377%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-sun"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-sunrise {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 81.55940594059406%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-sunrise"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-sunset {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 81.85643564356435%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-sunset"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-tablet {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 82.15346534653466%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-tablet"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-tag {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 82.45049504950495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-tag"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-target {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 82.74752475247524%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-target"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-terminal {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 83.04455445544555%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-terminal"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-thermometer {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 83.34158415841584%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-thermometer"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-thumbs-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 83.63861386138613%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-thumbs-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-thumbs-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 83.93564356435644%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-thumbs-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-toggle-left {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 84.23267326732673%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-toggle-left"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-toggle-right {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 84.52970297029702%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-toggle-right"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-tool {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 84.82673267326733%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-tool"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-trash {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 85.12376237623762%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-trash"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-trash-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 85.42079207920793%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-trash-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-trello {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 85.71782178217822%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-trello"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-trending-down {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 86.01485148514851%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-trending-down"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-trending-up {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 86.31188118811882%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-trending-up"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-triangle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 86.60891089108911%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-triangle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-truck {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 86.9059405940594%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-truck"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-tv {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 87.20297029702971%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-tv"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-twitch {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 87.5%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-twitch"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-twitter {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 87.79702970297029%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-twitter"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-type {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 88.0940594059406%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-type"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-umbrella {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 88.39108910891089%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-umbrella"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-underline {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 88.68811881188118%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-underline"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-unlock {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 88.98514851485149%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-unlock"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-upload {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 89.28217821782178%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-upload"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-upload-cloud {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 89.57920792079207%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-upload-cloud"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-user {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 89.87623762376238%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-user"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-user-check {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 90.17326732673267%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-user-check"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-user-minus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 90.47029702970298%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-user-minus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-user-plus {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 90.76732673267327%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-user-plus"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-user-x {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 91.06435643564356%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-user-x"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-users {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 91.36138613861387%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-users"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-video {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 91.65841584158416%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-video"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-video-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 91.95544554455445%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-video-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-voicemail {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 92.25247524752476%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-voicemail"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-volume {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 92.54950495049505%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-volume"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-volume-1 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 92.84653465346534%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-volume-1"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-volume-2 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 93.14356435643565%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-volume-2"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-volume-2-1 {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 93.44059405940594%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-volume-2-1"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-volume-x {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 93.73762376237623%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-volume-x"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-watch {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 94.03465346534654%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-watch"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-wifi {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 94.33168316831683%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-wifi"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-wifi-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 94.62871287128714%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-wifi-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-wind {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 94.92574257425743%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-wind"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-x {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 95.22277227722772%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-x"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-x-circle {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 95.51980198019803%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-x-circle"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-x-octagon {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 95.81683168316832%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-x-octagon"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-x-square {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 96.11386138613861%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-x-square"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-youtube {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 96.41089108910892%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-youtube"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-zap {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 96.70792079207921%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-zap"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-zap-off {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 97.0049504950495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-zap-off"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-zoom-in {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 97.3019801980198%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-zoom-in"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-line-icon-zoom-out {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 97.5990099009901%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="line-icon-zoom-out"] {
  --icon-width: 24;
  --icon-height: 24;
}
.css-logo-coda {
  --icon-width: 126;
  --icon-height: 30;
  --relative-pos: 0 97.96878870448353%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="logo-coda"] {
  --icon-width: 126;
  --icon-height: 30;
}
.css-logo-small {
  --icon-width: 164;
  --icon-height: 164;
  --relative-pos: 0 100%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}



[anchor="logo-small"] {
  --icon-width: 164;
  --icon-height: 164;
}
