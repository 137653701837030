.app .fstr-cookie-notice {
  --cookie-success-color: var(--bs-green);
  border-color: var(--bs-primary);
  max-width: 640px;
  padding: 3ex;
  border: 0;
  border-radius: 1rem;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 10px 28px,
    rgba(0, 0, 0, 0.22) 0px 6px 10px;

  &__title {
    @extend .h5;
    color: var(--bs-primary);
  }

  .toggle__label-text,
  &__toggle-row__content,
  &__content {
    font-size: 0.9rem;
  }

  &__content {
    margin-bottom: 0;
  }

  .button-group {
    .button:first-of-type {
    }

    .button--settings,
    .button.is--outline {
      @extend .button-outline;
      @extend .button--primary;
    }
  }

  &__toggle {
    margin-bottom: -2px;

    .button {
      border-radius: 0 3.125rem 3.125rem 0;
    }
  }

  .toggle-button {
    --button-size: 2rem;
    --button-padding-x: 1rem;
    --button-bg: transparent;
    --button-color: var(--bs-primary);
    border-color: inherit;

    &:hover {
      background-color: var(--bs-primary);
      color: var(--bs-light);
    }
  }

  .button--close {
    @include abs($spacer, 0, null, null);
    @include size(40px, 40px);
    font-size: 0px;
    // border-color: var(--bs-secondary);
    background-color: transparent;
    padding: 0;
    // border: 2px solid;
    color: var(--bs-primary);

    &::after,
    &::before {
      @include abs(50%, null, null, 50%);
      content: '';
      width: 18px;
      height: 3px;
      border-radius: 2px;
      background-color: currentColor;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: #fafafa;
  margin-bottom: 3ex;

  &__inner {
    @include abs(0, 0, 0, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .fstr-cookie-btn.button {
    font-size: 0.9em;
    margin-top: 3ex;
    background-color: var(--bs-primary);
  }
}
