body {
  --scrollbar-thumb-bg: var(--bs-secondary);
  --scrollbar-track-bg: var(--bs-secondary-100);
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-bg);
    border-radius: 10px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-thumb-bg);
    box-shadow: none;
  }
}
