.alert {
  --alert-padding-y: 2ex;
  --alert-padding-x: 2ex;
  --alert-border-radius: 1ex;
  --alert-border-color: transparent;

  padding: 1ex;
  display: block;
  // background-color: var(--alert-bg);
  background-color: color-mix(in srgb, var(--alert-bg) 50%, #fff);
  padding: var(--alert-padding-y) var(--alert-padding-x);
  // color: var(--alert-color);
  color: color-mix(in srgb, var(--alert-color) 50%, #000);
  border: 1px solid color-mix(in srgb, var(--alert-bg) 80%, #fff);
  border-radius: var(--alert-border-radius);

  @each $state in map-keys($theme-colors) {
    &.alert-#{$state} {
      //--alert-color: var(--#{$prefix}#{$state}-text-emphasis);
      --alert-color: var(--#{$prefix}#{$state});
      --alert-bg: var(--#{$prefix}#{$state}-bg-subtle);
      // --alert-border-color: var(--#{$prefix}#{$state}-border-subtle);
      --alert-link-color: var(--#{$prefix}#{$state});
    }
  }
}
