/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */

[class^='section-'] {
  position: relative;
}

[class^='section-negative-'] {
  z-index: 1;
}

@each $type, $prop in (inset: padding, outset: margin, negative: margin) {
  $class: 'section-#{$type}';
  $spacing: var(--section-spacing);

  // Use negative values for pull
  @if $type == negative {
    $spacing: calc(var(--section-spacing) * -1);
  }

  .#{$class}-y {
    #{$prop}-top: $spacing;
    #{$prop}-bottom: $spacing;
  }

  .#{$class}-x {
    #{$prop}-left: $spacing;
    #{$prop}-right: $spacing;
  }

  .#{$class}-s {
    #{$prop}-left: $spacing;
  }

  .#{$class}-t {
    #{$prop}-top: $spacing;
  }

  .#{$class}-b {
    #{$prop}-bottom: $spacing;
  }
}
