.guide {
  &-border-top {
    border-top: 1px solid var(--bs-secondary-bg-subtle);
  }

  &-container {
    margin-top: -1px;
  }

  &-label {
    //@include abs(0, 0, auto, 0);
    background-color: var(--bs-secondary-bg-subtle);
    color: var(--bs-secondary-color);
    padding: 0.2em 0.4em;
    display: inline-block;
    position: relative;
    z-index: 99;
  }
}
