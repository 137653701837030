@each $name, $value in $icon-sizes {
  .icon-#{$name} {
    min-width: $value !important;
    min-height: $value !important;

    //svg {
    //  @include size($value);
    //}
  }
}
