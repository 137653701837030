.page-image {
  position: relative;

  img {
    position: relative;
    z-index: 2;
    border-radius: var(--bs-border-radius);
    max-height: max(75vh, 600px);
    width: auto;
    max-width: 100%;
  }

  &::after {
    content: '';
    background-color: var(--bs-accent);
    @include abs(0);
    height: calc(var(--bs-border-radius) * 2);
  }
}
