// This file gets imported globally in the static folder
body {
  --topbar-height: calc(var(--bar-height, 0) + var(--subnav-height, 0));
}

.pt-topbar {
  padding-top: var(--topbar-height);
}
.mt-topbar {
  margin-top: var(--topbar-height);
}

.main-nav--active {
  overflow: hidden;
  height: 100vh;
}

.button.button-nav {
  --button-bg: var(--bs-primary);

  background-color: transparent;
  color: black;
  transition:
    color 0.2s,
    background-color 0.2s,
    border-color 0.1s;

  @media (hover: hover) {
    &:hover {
      border-color: black;
    }
  }

  &.is--active {
    border-color: var(--button-bg);
  }
}

//@media (hover: hover) {
//  .hover--underline-effect:hover {
//    text-decoration: underline !important;
//  }
//}
