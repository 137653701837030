// Function to determine contrast color
@function get-contrast-color($color, $threshold: 70%) {
  @return if(lightness($color) < $threshold, white, black);
}

@each $name, $color in $theme-colors {
  .button--#{$name} {
    --button-bg: #{$color};
    --button-color: #{get-contrast-color($color)};
  }
}

@each $name, $color in $button-themes {
  .button--#{$name} {
    --button-bg: #{map-get($color, 'bg')};
    --button-color: #{map-get($color, 'color')};
  }
}

// Custom overrides
.button--light-primary {
  @media (hover: hover) {
    &:hover {
      background-color: var(--bs-primary-bg-subtle);
    }
  }
}

.button--light-secondary {
  @media (hover: hover) {
    &:hover {
      background-color: var(--bs-secondary-bg-subtle);
    }
  }
}

.button--primary-subtle {
  --button-color: var(--bs-primary);

  @media (hover: hover) {
    &:hover {
      background-color: color-mix(in srgb, var(--button-bg) 80%, var(--bs-primary));
      --button-color: var(--bs-primary-text-emphasis);
    }
  }
}
.button--secondary-subtle {
  --button-color: var(--bs-secondary);

  @media (hover: hover) {
    &:hover {
      background-color: color-mix(in srgb, var(--button-bg) 80%, var(--bs-secondary));
      --button-color: var(--bs-secondary-text-emphasis);
    }
  }
}
