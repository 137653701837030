// COPIED FROM BOOTSTRAP ROOT, TO MAKE BOTH POSSIBLE (MEDIA QUERY AND CLASS)
[data-bs-theme='dark'],
.theme-dark {
  color-scheme: dark;

  // scss-docs-start root-dark-mode-vars
  --#{$prefix}body-color: #{$body-color-dark};
  --#{$prefix}body-color-rgb: #{to-rgb($body-color-dark)};
  --#{$prefix}body-bg: #{$body-bg-dark};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg-dark)};

  --#{$prefix}emphasis-color: #{$body-emphasis-color-dark};
  --#{$prefix}emphasis-color-rgb: #{to-rgb($body-emphasis-color-dark)};

  --#{$prefix}secondary-color: #{$body-secondary-color-dark};
  --#{$prefix}secondary-color-rgb: #{to-rgb($body-secondary-color-dark)};
  --#{$prefix}secondary-bg: #{$body-secondary-bg-dark};
  --#{$prefix}secondary-bg-rgb: #{to-rgb($body-secondary-bg-dark)};

  --#{$prefix}tertiary-color: #{$body-tertiary-color-dark};
  --#{$prefix}tertiary-color-rgb: #{to-rgb($body-tertiary-color-dark)};
  --#{$prefix}tertiary-bg: #{$body-tertiary-bg-dark};
  --#{$prefix}tertiary-bg-rgb: #{to-rgb($body-tertiary-bg-dark)};

  @each $color, $value in $theme-colors-text-dark {
    --#{$prefix}#{$color}-text-emphasis: #{$value};
  }

  @each $color, $value in $theme-colors-bg-subtle-dark {
    --#{$prefix}#{$color}-bg-subtle: #{$value};
  }

  @each $color, $value in $theme-colors-border-subtle-dark {
    --#{$prefix}#{$color}-border-subtle: #{$value};
  }

  --#{$prefix}heading-color: #{$headings-color-dark};

  --#{$prefix}link-color: #{$link-color-dark};
  --#{$prefix}link-hover-color: #{$link-hover-color-dark};
  --#{$prefix}link-color-rgb: #{to-rgb($link-color-dark)};
  --#{$prefix}link-hover-color-rgb: #{to-rgb($link-hover-color-dark)};

  --#{$prefix}code-color: #{$code-color-dark};
  --#{$prefix}highlight-color: #{$mark-color-dark};
  --#{$prefix}highlight-bg: #{$mark-bg-dark};

  --#{$prefix}border-color: #{$border-color-dark};
  --#{$prefix}border-color-translucent: #{$border-color-translucent-dark};

  --#{$prefix}form-valid-color: #{$form-valid-color-dark};
  --#{$prefix}form-valid-border-color: #{$form-valid-border-color-dark};
  --#{$prefix}form-invalid-color: #{$form-invalid-color-dark};
  --#{$prefix}form-invalid-border-color: #{$form-invalid-border-color-dark};

  // Custom
  @include tint-css-variables('primary', 'dark');
  @include tint-css-variables('secondary', 'dark');
}
