.section-header {
  display: flex;
  gap: spacer(3);
  align-items: flex-end;

  &__main {
    flex-grow: 1;
    min-width: 0;
  }
}
