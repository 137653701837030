// ABCRepro
$font-name: 'ABCRepro';

@font-face {
  font-family: 'ABCRepro';
  src:
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Regular.woff2') format('woff2'),
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ABCRepro';
  src:
    url('#{$fonts-path}#{$font-name}/#{$font-name}-RegularItalic.woff2') format('woff2'),
    url('#{$fonts-path}#{$font-name}/#{$font-name}-RegularItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'ABCRepro';
  src:
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Medium.woff2') format('woff2'),
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ABCRepro';
  src:
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Bold.woff2') format('woff2'),
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ABCRepro';
  src:
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Black.woff2') format('woff2'),
    url('#{$fonts-path}#{$font-name}/#{$font-name}-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
