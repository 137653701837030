body {
  --select-button-bg: #ac6cff;
  --select-button-color: white;

  --select-button-focus-color: var(--bs-accent);

  --select-button-active-bg: var(--bs-accent);
  --select-button-active-color: black;
  --select-button-active-focus-color: var(--bs-secondary);
}

// button-select
.button.button--select {
  --button-bg: var(--select-button-bg);
  --button-color: var(--select-button-color);
  height: 56px;
  font-weight: 400;

  &:focus {
    --button-color: var(--select-button-focus-color);
  }

  &:hover {
    background-color: color-mix(in srgb, var(--button-bg), black 10%);
  }

  &.has--value {
    --button-bg: var(--select-button-active-bg);
    --button-color: var(--select-button-active-color);

    &:focus {
      --button-color: var(--select-button-active-focus-color);
    }
  }
  &-more {
    --button-bg: #8e36ff;
    height: 56px;
    padding-right: 25px;
    border: 0px;
  }
}

.mat-mdc-form-field.form-field--button-select {
  --mdc-filled-text-field-container-color: var(--select-button-bg);
  --mdc-filled-text-field-label-text-color: var(--select-button-color);
  --mat-form-field-trailing-icon-color: var(--select-button-color);
  --mdc-filled-text-field-hover-label-text-color: var(--select-button-color);

  --mdc-filled-text-field-focus-label-text-color: var(--select-button-focus-color);
  --mdc-filled-text-field-focus-caret-color: var(--select-button-focus-color);

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  &:hover .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: color-mix(in srgb, var(--mdc-filled-text-field-container-color), black 10%);
  }

  &.mat-focused {
    --mat-form-field-trailing-icon-color: var(--select-button-focus-color);
  }

  .mdc-text-field--filled {
    border-radius: 100px;
    transition: background-color 200ms ease-in-out;
  }

  .mat-mdc-floating-label {
    transform: translateY(-50%);
    top: 50%;
  }
  .mat-mdc-select-value {
    opacity: 0;
    pointer-events: none;
  }

  .mat-mdc-select-value-text {
    max-width: 0px !important;
    display: block;
  }

  &.has--value {
    --mdc-filled-text-field-container-color: var(--select-button-active-bg);
    --mdc-filled-text-field-label-text-color: var(--select-button-active-color);
    --mdc-filled-text-field-hover-label-text-color: var(--select-button-active-color);
    --mat-form-field-trailing-icon-color: var(--select-button-active-color);

    --mdc-filled-text-field-focus-label-text-color: var(--select-button-active-focus-color);
    --mdc-filled-text-field-focus-caret-color: var(--select-button-active-focus-color);
    --mat-form-field-focus-trailing-icon-color: var(--select-button-active-focus-color);
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  //auto width
  .mat-mdc-form-field-infix {
    width: auto;
  }

  .mdc-floating-label {
    position: relative;
    top: 0;
    max-width: unset !important;
  }

  .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: calc(var(--mat-form-field-container-height) / 2 - 1px);
    letter-spacing: 0;
  }
  //end auto width
}
