.panel-cloud {
  --panel-cloud-chevron-size: 0px; // 10px
  //transform: translateY(10px);

  padding-top: 20px;
  border-radius: 20px !important;
  background: white;
  padding-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !important;
  margin-top: var(--panel-cloud-chevron-size);

  &::before {
    content: '';
    @include size(0);
    @include abs(0, 0, null, 30px);
    //transform: translateY(-100%);
    z-index: 2;

    border-left: var(--panel-cloud-chevron-size) solid transparent;
    border-right: var(--panel-cloud-chevron-size) solid transparent;
    border-bottom: calc(var(--panel-cloud-chevron-size) * 1.1) solid white;
    transform: translateY(-100%);
  }

  .mat-mdc-select-panel,
  .mat-mdc-menu-panel {
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0;
  }

  @include media-breakpoint-down(md) {
    left: 20px !important;
    right: 20px !important;
    width: auto !important;

    &::before {
      display: none;
    }
  }
}

.panel-cloud-menu {
  @extend .panel-cloud;
  padding-top: 0;
  padding-bottom: 0;

  &::before {
    transform: none;
  }

  .mat-mdc-menu-panel {
    border-radius: 20px !important;
  }
}
